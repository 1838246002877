  ::ng-deep .mat-mdc-form-field {
    font-size: 14px;
    width: 100%;
  }
  
  .mat-mdc-form-field {
    font-size: 14px;
    width: 100%;
  }
  
  ::ng-deep .mat-mdc-text-field-wrapper {
    height: 40px !important;
  }
  ::ng-deep .mat-mdc-form-field-flex {
    margin-top: -7px !important;
  }
  
  ::ng-deep .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-infix {
    font-size: inherit;
    line-height: 1;
  }
  
  ::ng-deep .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    font-size: inherit;
    line-height: 1;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  
  .grid-container-60-40 {
    display: grid;
    grid-template-columns: 60% 40%;
  }
  
  .grid-container-auto {
    display: grid;
    grid-template-columns: auto auto;
  }
  
  
  .border-red-1 {
    border: 1px solid red;
  }
  
  .border-grey-1 {
    border: 1px solid #ECE8E8;
  }
  
  .border-radius-1 {
    border-radius: 5px;
  }
  
  .border-radius-2 {
    border-radius: 10px;
  }
  .cursor {
    cursor: pointer;
  }
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  
  .no-text-deco {
    text-decoration: none;
  }
  
  .yellow {
    background-color: #FFBE2A;
  }
  
  .green {
    background-color: #57D52F;
  }
  
  .pale-blue {
    background-color: #B7D8FF80;
  }

  .float-right {
    float: right;
  }
  
  .float-left {
    float: left;
  }
  
  .float-none {
    float: none;
  }
  
  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  .text-base {
    font-size: 1.05rem;
    line-height: 1.5rem;
  }
  
  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  
  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  
  .text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  
  .text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  
  .text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  
  .font-light {
    font-weight: 300;
  }
  
  .font-mid {
    font-weight: 400;
  }
  
  .font-normal {
    font-weight: 500;
  }
  
  .font-semibold {
    font-weight: 600;
  }
  
  .font-bold {
    font-weight: 700;
  }
  
  .margin-auto {
    margin: auto 0;
  }
  .m-0 {
    margin: 0 !important;
  }
  .m-1 {
    margin: 0.25rem !important;
  }
  .m-2 {
    margin: 0.5rem !important;
  }
  .m-3 {
    margin: 1rem !important;
  }
  .m-4 {
    margin: 1.5rem !important;
  }
  .m-5 {
    margin: 3rem !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .mt-03 {
    margin-top: -0.2rem !important;
  }
  .mt-1 {
    margin-top: 0.25rem !important;
  }
  .mt-2 {
    margin-top: 0.5rem !important;
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .mt-4 {
    margin-top: 1.5rem !important;
  }
  .mt-5 {
    margin-top: 3rem !important;
  }
  .mt-6 {
    margin-top: 4rem !important;
  }
  .mt-7 {
    margin-top: 5rem !important;
  }
  .mt-auto {
    margin-top: auto !important;
  }
  .me-0 {
    margin-right: 0 !important;
  }
  .me-1 {
    margin-right: 0.25rem !important;
  }
  .me-2 {
    margin-right: 0.5rem !important;
  }
  .me-3 {
    margin-right: 1rem !important;
  }
  .me-4 {
    margin-right: 1.5rem !important;
  }
  .me-5 {
    margin-right: 3rem !important;
  }
  .me-auto {
    margin-right: auto !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-5 {
    margin-bottom: 3rem !important;
  }
  .mb-auto {
    margin-bottom: auto !important;
  }
  .ms-0 {
    margin-left: 0 !important;
  }
  .ms-01 {
    margin-left: -1.15rem !important;
    width: 115%;
  }
  .ms-1 {
    margin-left: 0.25rem !important;
  }
  .ms-2 {
    margin-left: 0.5rem !important;
  }
  .ms-3 {
    margin-left: 1rem !important;
  }
  .ms-4 {
    margin-left: 1.5rem !important;
  }
  .ms-5 {
    margin-left: 3rem !important;
  }
  .ms-auto {
    margin-left: auto !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .p-3 {
    padding: 1rem !important;
  }
  .p-4 {
    padding: 1.5rem !important;
  }
  .p-5 {
    padding: 3rem !important;
  }
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-0 {
    padding-top: 0 !important;
  }
  .pt-1 {
    padding-top: 0.25rem !important;
  }
  .pt-2 {
    padding-top: 0.5rem !important;
  }
  .pt-3 {
    padding-top: 1rem !important;
  }
  .pt-4 {
    padding-top: 1.5rem !important;
  }
  .pt-5 {
    padding-top: 3rem !important;
  }
  .pe-0 {
    padding-right: 0 !important;
  }
  .pe-1 {
    padding-right: 0.25rem !important;
  }
  .pe-2 {
    padding-right: 0.5rem !important;
  }
  .pe-3 {
    padding-right: 1rem !important;
  }
  .pe-4 {
    padding-right: 1.5rem !important;
  }
  .pe-5 {
    padding-right: 3rem !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-3 {
    padding-bottom: 1rem !important;
  }
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-5 {
    padding-bottom: 3rem !important;
  }
  .ps-0 {
    padding-left: 0 !important;
  }
  .ps-1 {
    padding-left: 0.25rem !important;
  }
  .ps-2 {
    padding-left: 0.5rem !important;
  }
  .ps-3 {
    padding-left: 1rem !important;
  }
  .ps-4 {
    padding-left: 1.5rem !important;
  }
  .ps-5 {
    padding-left: 3rem !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .vw-100 {
    width: 100vw !important;
  }
  .min-vw-100 {
    min-width: 100vw !important;
  }
  .h-25 {
    height: 25% !important;
  }
  .h-50 {
    height: 50% !important;
  }
  .h-75 {
    height: 75% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .h-auto {
    height: auto !important;
  }
  .mh-100 {
    max-height: 100% !important;
  }
  .vh-100 {
    height: 100vh !important;
  }
  .min-vh-100 {
    min-height: 100vh !important;
  }
  .justify-content-start {
    justify-content: flex-start !important;
  }
  .justify-content-end {
    justify-content: flex-end !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .justify-content-around {
    justify-content: space-around !important;
  }
  .justify-content-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-start {
    align-items: flex-start !important;
  }
  .align-items-end {
    align-items: flex-end !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .align-items-baseline {
    align-items: baseline !important;
  }
  .align-items-stretch {
    align-items: stretch !important;
  }
  .align-content-start {
    align-content: flex-start !important;
  }
  .align-content-end {
    align-content: flex-end !important;
  }
  .align-content-center {
    align-content: center !important;
  }
  .align-content-between {
    align-content: space-between !important;
  }
  .align-content-around {
    align-content: space-around !important;
  }
  .align-content-stretch {
    align-content: stretch !important;
  }
  .align-self-auto {
    align-self: auto !important;
  }
  .align-self-start {
    align-self: flex-start !important;
  }
  
  .align-self-end {
    align-self: flex-end !important;
  }
  
  .align-self-center {
    align-self: center !important;
  }
  
  .align-self-baseline {
    align-self: baseline !important;
  }
  
  .align-self-stretch {
    align-self: stretch !important;
  }
  
  .order-first {
    order: -1 !important;
  }
  
  .order-0 {
    order: 0 !important;
  }
  
  .order-1 {
    order: 1 !important;
  }
  
  .order-2 {
    order: 2 !important;
  }
  
  .order-3 {
    order: 3 !important;
  }
  
  .order-4 {
    order: 4 !important;
  }
  
  .order-5 {
    order: 5 !important;
  }
  
  .order-last {
    order: 6 !important;
  }
  
  .p-1 {
    padding: 0.25rem;
  }
  
  .p-2 {
    padding: 0.5rem;
  }
  
  .p-3 {
    padding: 0.75rem;
  }
  
  .p-4 {
    padding: 1rem;
  }
  
  .p-5 {
    padding: 1.25rem;
  }
  
  .p-8 {
    padding: 2rem;
  }
  
  .p-14 {
    padding: 3.5rem;
  }
  
  .p-16 {
    padding: 4rem;
  }
  
  .px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  
  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  
  .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  
  .py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  
  .py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  
  .py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  .pb-1 {
   padding-bottom: 1rem;
  }
  
  .pb-2 {
   padding-bottom: 2rem;
  }
  
  .pb-3 {
   padding-bottom: 3rem;
  }
  
  .pb-4 {
   padding-bottom: 4rem;
  }
  
  .pb-55 {
   padding-bottom: 5rem;
  }
  
  .pb-6 {
   padding-bottom: 6rem;
  }
  
  .pb-7 {
   padding-bottom: 7rem;
  }
  
  .px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  
  .px-14 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  
  .py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  
  .px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  
  .py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  
  .px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  
  .py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  
  .px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  
  .py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  
  .px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  
  .pt-0 {
    padding-top: 0;
  }
  
  .-pb-1 {
    padding-bottom: -1rem;
  }
  
  .pt-1 {
    padding-top: 0.25rem;
  }
  
  .-pb-2 {
    padding-bottom: -2rem;
  }
  
  .-pb-3 {
    padding-bottom: -3rem;
  }
  
  .-pb-4 {
    padding-bottom: -4rem;
  }
  
  .pt-2 {
    padding-top: 0.5rem;
  }
  
  .pb-2 {
    padding-bottom: 0.5rem;
  }
  
  .pl-3 {
    padding-left: 0.75rem;
  }
  
  .pr-4 {
    padding-right: 1rem;
  }
  
  .pb-4 {
    padding-bottom: 1rem;
  }
  
  .pl-4 {
    padding-left: 1rem;
  }
  
  .pt-6 {
    padding-top: 1.5rem;
  }
  
  .pb-6 {
    padding-bottom: 1.5rem;
  }
  
  .pt-8 {
    padding-top: 2rem;
  }
  
  .pl-10 {
    padding-left: 2.5rem;
  }
  
  .pt-12 {
    padding-top: 3rem;
  }
  
  .pr-12 {
    padding-right: 3rem;
  }
  
  .pt-16 {
    padding-top: 4rem;
  }
  
  .pb-16 {
    padding-bottom: 4rem;
  }
  
  .pt-20 {
    padding-top: 5rem;
  }
  
  .pb-20 {
    padding-bottom: 5rem;
  }
  
  .pt-24 {
    padding-top: 6rem;
  }
  
  .pt-32 {
    padding-top: 8rem;
  }
  
  .pb-32 {
    padding-bottom: 8rem;
  }
  
  .pb-40 {
    padding-bottom: 10rem;
  }
  
  .pt-40 {
    padding-top: 10rem;
  }
  
  .pt-48 {
    padding-top: 12rem;
  }
  
  .pb-48 {
    padding-bottom: 12rem;
  }
  
  .pb-56 {
    padding-bottom: 14rem;
  }
  
  .pb-64 {
    padding-bottom: 16rem;
  }
  
  .table-auto {
    table-layout: auto;
  }
  
  .table-fixed {
    table-layout: fixed;
  }
  
  .text-left {
    text-align: left;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }
  
  .text-justify {
    text-align: justify;
  }
  
  .text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  
  .text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  
  .text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  
  .text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  
  .text-lightBlue-400 {
    --tw-text-opacity: 1;
    color: rgba(56, 189, 248, var(--tw-text-opacity));
  }
  
  .text-lightBlue-600 {
    --tw-text-opacity: 1;
    color: rgba(2, 132, 199, var(--tw-text-opacity));
  }
  
  .text-teal-500 {
    --tw-text-opacity: 1;
    color: rgba(20, 184, 166, var(--tw-text-opacity));
  }
  
  .text-emerald-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  
  .text-orange-500 {
    --tw-text-opacity: 1;
    color: rgba(249, 115, 22, var(--tw-text-opacity));
  }
  
  .text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  
  .text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  
  .text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  
  .text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(113, 113, 122, var(--tw-text-opacity));
  }
  
  .text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(82, 82, 91, var(--tw-text-opacity));
  }
  
  .text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(63, 63, 70, var(--tw-text-opacity));
  }
  
  .text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(24, 24, 27, var(--tw-text-opacity));
  }
  
  .text-blueGray-100 {
    --tw-text-opacity: 1;
    color: rgba(241, 245, 249, var(--tw-text-opacity));
  }
  
  .text-blueGray-200 {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }
  
  .text-blueGray-300 {
    --tw-text-opacity: 1;
    color: rgba(203, 213, 225, var(--tw-text-opacity));
  }
  
  .text-blueGray-400 {
    --tw-text-opacity: 1;
    color: rgba(148, 163, 184, var(--tw-text-opacity));
  }
  
  .text-blueGray-500 {
    --tw-text-opacity: 1;
    color: rgba(100, 116, 139, var(--tw-text-opacity));
  }
  
  .text-blueGray-600 {
    --tw-text-opacity: 1;
    color: rgba(71, 85, 105, var(--tw-text-opacity));
  }
  
  .text-blueGray-700 {
    --tw-text-opacity: 1;
    color: rgba(51, 65, 85, var(--tw-text-opacity));
  }
  
  .text-blueGray-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 41, 59, var(--tw-text-opacity));
  }
  
  .text-button-red {
   background-color: #E70000;
   }
  



/* MEDIA QUERIES */
/* MEDIA QUERIES */
/* MEDIA QUERIES */
/* MEDIA QUERIES */


@media screen and (max-width: 1092.9px) {
  .margin-auto {
    /* margin: auto 0; */
    font-size: 9px;
  }
}