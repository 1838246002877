@import url(https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700);
/* @import "./camptonFont.css"; */
@import "./sora-font.css";

[data-notify="container"][class*="alert-pastel-"] {
  background-color: rgb(255, 255, 255);
  border-width: 0px;
  border-left: 15px solid rgb(255, 240, 106);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(51, 51, 51, 0.3);
  font-family: SoraRegular, 'Old Standard TT', serif;
  letter-spacing: 1px;
  /* width: auto; */
  height: auto;
  padding: 10px;
  transition: all 1.5s linear !important;
  z-index: 3000 !important;

  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.5s;
  animation: slide 0.5s forwards !important;
  animation-delay: 0.5s !important;
}

@-webkit-keyframes slide {
  100% {
    right: 3px;
  }
}

@keyframes slide {
  100% {
    right: 3px;
  }
}

[data-notify="container"].alert-pastel-info {
  border-left-color: rgb(255, 179, 40);
}

[data-notify="container"].alert-pastel-danger {
  border-left-color: rgb(255, 103, 76);
  margin-bottom: 7%;
}

[data-notify="container"].alert-pastel-success {
  border-left-color: rgb(17, 167, 4);
}

[data-notify="container"][class*="alert-pastel-"]>[data-notify="title"] {
  font-family: SoraBold;
  color: rgb(80, 80, 57);
  display: block;
  font-weight: 700;
  margin-bottom: 5%;
}

[data-notify="container"][class*="alert-pastel-"]>[data-notify="message"] {
  font-family: SoraRegular;
  font-weight: 400;
  margin-bottom: 5px;
}

/* @media screen and (min-height: 700px) {
  [data-notify="container"][class*="alert-pastel-"] {
    top: 78% !important;
  }
}

@media screen and (min-height: 900px) {
  [data-notify="container"][class*="alert-pastel-"] {
    bottom: 20% !important;
  }
} */
