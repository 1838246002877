@import "../../../node_modules/angular-calendar/css/angular-calendar.css";

/* You can add global styles to this file, and also import other style files */
:root {
  --Success-100: rgba(215, 240, 229, 1);
  --primary-nav-background: #0F3F50;
  --secondary-nav-background: #175E77;
  --active-menu-divider: #fca311;
}

@import "./material.css";
@import "./notify.css";
@import "./sora-font.css";
/* Updated path */



@use '@angular/material' as mat;
$sora-typography: mat.define-typography-config($font-family: '"SoraRegular"');
@include mat.typography-hierarchy($sora-typography);
$app-theme: mat.define-light-theme((typography: $sora-typography));
@include mat.all-component-themes($app-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: SoraRegular, "Helvetica Neue", sans-serif !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: -webkit-gradient(linear, left top, left bottom, from(#B1A8A8), to(#B1A8A8));
  box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .25), inset -2px -2px 2px rgba(0, 0, 0, .25)
}

::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  background: linear-gradient(to right, #bebdbd, #bebdbd 1px, #bebdbd 1px, #bebdbd)
}

.container {
  background: rgba(213, 242, 252, 1);
  height: 100vh;
}

.success-container {
  background: var(--Success-100, #D7F0E5);
  height: 100vh;
}

.onboarding-container,
.reuseable-container {
  /* background: rgb(178, 178, 179); */
  background: rgba(99, 189, 239, 1);
  /* background: rgba(46, 189, 239, 1); */
}

::ng-deep .ql-snow .ql-editor img {
  max-width: 30% !important;
  /* Ensure the image fits within the editor's width */
  height: auto;
  /* Maintain aspect ratio */
  margin: 10px 0;
  /* Add some spacing around the images */
  border-radius: 5px;
  /* Optionally add rounded corners */
}

::ng-deep .ql-editor p {

  /* border: 0.5px solid #ccc; */
  /* padding: 0 0.5rem;
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 0.5rem; */
}

::ng-deep .ql-editor .custom-attachment img {
  width: 16px;
  height: 16px;
}

::ng-deep .ql-editor .custom-attachment a {
  text-decoration: none;
  color: inherit;
}

/* ::ng-deep .mat-mdc-checkbox .mdc-checkbox__ripple {
  display: none !important;
} */

/* ::ng-deep .mat-mdc-checkbox-ripple {
  display: none !important;
} */


/* CALENDAR HEADER CELLS */
/* CALENDAR HEADER CELLS */
/* CALENDAR HEADER CELLS */
/* CALENDAR HEADER CELLS */

.selected-view {
  padding-left: 4rem;
}

.selected-view .calendar-day-content {
  margin: .5rem .15rem .5rem 4rem;
  flex-direction: column;
  display: flex;
  width: 138.286px;
  padding: var(--Spacing-space-7, 12px) var(--Spacing-space-5, 8px);
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--Grey-Scale-900, #1C2122);
  cursor: pointer;
  gap: 0.5rem;
}

.selected-view .calendar-day-content .first-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
}

.selected-view .calendar-day-content .first-item span:first-child {
  overflow: hidden;
  color: var(--Grey-Scale-300, #DDE0E1);
  text-align: center;
  text-overflow: ellipsis;

  /* Typography@12/400 */
  font-family: SoraRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
}

.selected-view .calendar-day-content .first-item span:nth-child(2) {
  color: var(--Grey-Scale-0, #FFF);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: SoraRegular;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 27px */
}


.selected-view .calendar-day-content .second-item div {
  display: flex;
  gap: 0.2rem;
}

.selected-view .calendar-day-content .second-item {
  display: flex;
  padding: var(--Spacing-space-3, 4px) var(--Spacing-space-5, 8px);
  background-color: #5C5F60;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Spacing-space-5, 8px);
  border-radius: 48px;
}

.selected-view .calendar-day-content .second-item span:first-child {
  color: var(--Primary-600, #2EBDEF);
  text-align: center;

  /* Typography@12/400 */
  font-family: SoraRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
}

.selected-view .calendar-day-content .second-item span:nth-child(2) {
  color: var(--Grey-Scale-0, #FFF);

  /* Typography@12/400 */
  font-family: SoraRegular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.cal-month-view .cal-header {
  background-color: #F1F3F3;
}

.cal-month-view .cal-header .cal-cell {
  padding: 35px 0;
  margin: .5rem .15rem;
  height: 4rem;
  font-size: 12px;
  font-family: SoraRegular;
  display: flex;
  padding: var(--Spacing-space-7, 12px) var(--Spacing-space-5, 8px);
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  background: var(--Grey-Scale-300, #DDE0E1);
  cursor: pointer;
}

.cal-week-view .cal-day-headers {
  background-color: #F1F3F3;
  border-bottom: none;
}

.cal-week-view .cal-day-headers .cal-header br {
  display: none;
}

.cal-week-view .cal-day-headers .cal-header {
  height: 4rem;
  padding: 35px 0;
  margin: .5rem .15rem;
  /* background-color: #DDE0E1; */
  /* border-radius: 5px; */
  display: flex;
  flex-direction: row;
  /* flex-direction: column; */
  padding: var(--Spacing-space-7, 12px) var(--Spacing-space-5, 8px);
  justify-content: space-between;
  /* align-items: flex-start; */
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  background: var(--Grey-Scale-300, #DDE0E1);
}

.cal-week-view .cal-day-headers .cal-header:hover {
  cursor: pointer;
}

.cal-week-view .cal-day-headers .cal-header b,
.cal-week-view .cal-day-headers .cal-header span {
  font-size: 12px;
  font-family: SoraRegular;
}

.cal-week-view .cal-time-label-column .cal-hour {
  background-color: #F1F3F3;
  border-top: none;
}

.cal-week-view .cal-time-label-column .cal-hour .cal-hour-segment {
  border: none;
}

.tableDataClass {
  height: calc(100vh - 10rem) !important;
  border-radius: 1rem;
}

.inventoryDataClass {
  height: calc(100vh - 13.5rem) !important;
  border-radius: 1rem;
}

/* EMAIL COMPOSE Cc/Bcc */
/* EMAIL COMPOSE Cc/Bcc */
/* EMAIL COMPOSE Cc/Bcc */

.chip-height .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  max-width: 80%;
  max-height: 37px;
  overflow: hidden;
}

.chip-height .mat-mdc-chip-set .mdc-evolution-chip-set__chips:hover {
  overflow: auto;
}

.chip-height .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
  margin-left: -8px;
  margin-right: 0;
  margin-top: -1px;
}


/* MEDIA QUERIES */
/* MEDIA QUERIES */
/* MEDIA QUERIES */
/* MEDIA QUERIES */

@media screen and (min-width: 1706px) {
  /* .ql-toolbar.ql-snow + .ql-container.ql-snow {
    min-height: calc(35vh - 3rem) !important;
  } */
}


/* @media screen and (max-width: 1600.9px) {
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: calc(100vh - 3.9rem);
    border: 1px solid red;
  }
} */


@media screen and (min-width: 1408px) {
  .mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
    line-height: 15px;
    font-size: 15px;
  }

  .email-preview .display-selected-email-item img {
    max-width: 300px;
  }

  /* .ql-toolbar.ql-snow + .ql-container.ql-snow {
    min-height: calc(30.5vh - 3.9rem);
  } */
}


@media screen and (max-width: 1407.9px) {
  .mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
    line-height: 15px;
    /* font-size: 15px; */
    font-size: 12px;
  }

  .email-preview .display-selected-email-item img {
    max-width: 300px;
    /* width: 150px !important; */
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    min-height: calc(30.5vh - 3.9rem);
  }
}


@media screen and (max-width: 1397.9px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 5px !important;
  }
}


@media screen and (max-width: 1300.9px) {
  .cal-week-view .cal-time {
    font-size: 13px;
    font-weight: bold;
    padding-top: 6.5px;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    font-size: 14px;
  }

  .mat-mdc-form-field-infix {
    width: 160px !important;
  }
}



@media screen and (max-width: 1289.9px) {
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    width: 24px !important;
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    min-height: calc(30.5vh - 5.9rem);
  }
}


/* @media screen and (max-width: 1240px) {
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 7px !important;
  }
} */


@media screen and (max-width: 1239.9px) {
  .mat-mdc-form-field-infix {
    width: 150px !important;
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    width: 21px !important;
    padding: 0;
    height: 0;
  }

  .mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
    font-size: 14px;
  }

  .cal-week-view .cal-day-headers .cal-header b, .cal-week-view .cal-day-headers .cal-header span {
    font-size: 10px;
  }
}



@media screen and (max-width: 1214.9px) {
  .cal-week-view .cal-time {
    font-size: 12px;
    font-weight: bold;
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    width: 18px !important;
  }
}

@media screen and (max-width: 1148.9px) {
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    width: 16px !important;
  }
}


@media screen and (max-width: 1110px) {
  .mat-mdc-checkbox-touch-target {
    height: 20px;
    width: 20px;
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    width: 15px !important;
    height: 18px !important;
  }

  .ql-snow.ql-toolbar .ql-clean, .ql-snow.ql-toolbar .ql-link,
  .ql-snow.ql-toolbar .ql-image, .ql-snow.ql-toolbar .ql-video {
    /* margin-top: 10px !important; */
    margin-top: 5px !important;
  }

  .ql-snow .ql-picker {
    font-size: 11px !important;
    height: 16px !important;
  }

  .ql-snow .ql-color-picker, .ql-snow .ql-icon-picker {
    width: 26px;
  }

  .ql-snow .ql-picker.ql-size, .ql-snow .ql-picker.ql-header, .ql-snow .ql-picker.ql-font {
    width: 64px !important;
  }

  .ql-snow .ql-picker.ql-font {
    width: 79px !important;
  }

  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    width: 16px !important;
    margin-top: -5px !important;
  }

  .ql-snow .ql-toolbar button svg {
    width: 15px;
  }

  /* .ql-toolbar.ql-snow + .ql-container.ql-snow {
    min-height: calc(100vh - 25.9rem) !important;
  } */

  .mat-mdc-form-field-infix {
    width: 140px !important;
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    font-size: 13px;
  }

  .email_form .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    font-size: 11px;
    width: 9rem;
  }

  .mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
    font-size: 13px;
  }

  .mat-mdc-paginator-page-size-label {
    font-size: 10px;
  }

  .mat-mdc-paginator-range-label {
    font-size: 10px;
  }

  .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
    width: 18px;
  }

  .selected-view .calendar-day-content {
    width: 95.3px;
  }

  .selected-view .calendar-day-content .first-item span:first-child {
    font-size: 10px;
  }

  .selected-view .calendar-day-content .first-item span:nth-child(2) {
    font-size: 13px;
  }

  .selected-view .calendar-day-content .second-item span:first-child,
  .selected-view .calendar-day-content .second-item span:nth-child(2) {
    font-size: 8px;
  }

  .cal-week-view .cal-time {
    font-size: 10px;
    padding-top: 8px;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: 41px;
    width: 71px;
  }

  .mat-mdc-icon-button svg {
    width: 18px !important;
  }

  .mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-size: 10.5px !important;
  }

  .mdc-text-field--outlined .mdc-notched-outline  {
    /* height: 35px; */
  }

  .cal-month-view .cal-header .cal-cell {
    font-size: 8px;
  }

  .cal-week-view .cal-day-headers .cal-header b, .cal-week-view .cal-day-headers .cal-header span {
    font-size: 8px;
  }

  .cal-week-view .cal-day-headers .cal-header {
    padding: var(--Spacing-space-7, 11px) var(--Spacing-space-5, 5px);
  }

  .cal-month-view .cal-day-cell {
    font-size: 10px;
    padding: 5px;
  }
}