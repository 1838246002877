@font-face {
   font-family: SoraBold;
   src: url("/assets/fonts/sora/static/Sora-Bold.ttf") format("truetype");
}

@font-face {
   font-family: SoraExtraBold;
   src: url("/assets/fonts/sora/static/Sora-ExtraBold.ttf") format("truetype");
}

@font-face {
   font-family: SoraExtraLight;
   src: url("/assets/fonts/sora/static/Sora-ExtraLight.ttf") format("truetype");
}

@font-face {
   font-family: SoraLight;
   src: url("/assets/fonts/sora/static/Sora-Light.ttf") format("truetype");
}

@font-face {
   font-family: SoraMedium;
   src: url("/assets/fonts/sora/static/Sora-Medium.ttf") format("truetype");
}

@font-face {
   font-family: SoraRegular;
   src: url("/assets/fonts/sora/static/Sora-Regular.ttf") format("truetype");
}

@font-face {
   font-family: SoraSemiBold;
   src: url("/assets/fonts/sora/static/Sora-SemiBold.ttf") format("truetype");
}

@font-face {
   font-family: SoraThin;
   src: url("/assets/fonts/sora/static/Sora-Thin.ttf") format("truetype");
}

@font-face {
   font-family: SoraVariable;
   src: url("/assets/fonts/sora/Sora-VariableFont_wght.ttf") format("truetype");
}